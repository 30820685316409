import React, { useState } from 'react'

import { Label } from 'evergreen-ui'

import { DoubleTextInput, TextInputField, RadioGroup, CandidTerms } from '@pearly/lib'
import { useGlobal } from 'components/global-provider'
import * as Types from 'types/graphql'

export type Props = {
    TBCustomerNumber: any,
    accountStatus: boolean
}

const CandidUserFields = ({ TBCustomerNumber, accountStatus, ...props }: Props) => {
    const global = useGlobal()
    const isSystemAccount = global.account?.type === Types.AccountType.SYSTEM
    return (
        <>
            { (isSystemAccount &&  accountStatus) ? 
                <>
                    <Label marginBottom={4} alignSelf="flex-start">CandidPro Customer Number</Label>
                    <TextInputField name="candidProCustomerNumber" type="text" placeholder="CandidPro Customer Number" />
                </> 
                :
                <>
                </>
            }
            <Label marginBottom={4} alignSelf="flex-start">Practice Name</Label>
            <TextInputField name="practiceName" type="text" placeholder="Practice Name" />
            <Label marginBottom={4} alignSelf="flex-start">Name</Label>
            <DoubleTextInput name="doctorFirstName" half="top" width="100%" type="text" placeholder="Doctor's First Name" />
            <DoubleTextInput name="doctorLastName" half="bottom" width="100%" type="text" placeholder="Doctor's Last Name" />
            <Label marginBottom={4} alignSelf="flex-start">Doctor's Email</Label>
            <TextInputField name="doctorEmail" type="email" placeholder="john@planforhealth.com" />
            <RadioGroup
                name="allowLoginToDoctors"
                label="Would you like to provide logins to other doctors in your practice?"
                size={16}
                marginBottom={16}
                options={[
                    { label: 'Yes', value: 'YES' },
                    { label: 'No', value: 'NO' }
                ]}
            />
            <Label marginBottom={4} alignSelf="flex-start">Shipping Address</Label>
            <TextInputField name="shippingAddress1" type="text" placeholder="Shipping Address-(Line 1)" />
            <TextInputField name="shippingAddress2" type="text" placeholder="Shipping Address-(Line 2) optional" />
            <TextInputField name="shippingCity" type="text" placeholder="Shipping Address-City" />
            <TextInputField name="shippingState" type="text" placeholder="Shipping Adddress-State" />
            <TextInputField name="shippingZip" type="number" placeholder="Shipping Address-Zip Code" />
            <TextInputField name="shippingCountry" type="text" placeholder="Shipping Address-Country" />
            <Label marginBottom={4} alignSelf="flex-start">TruBlu Direct ID</Label>
            <TextInputField value={!isSystemAccount ? global.account?.code : TBCustomerNumber} disabled={true} name={''} />
            <RadioGroup
                name="candidProProvider"
                label="Are you an existing CandidPro provider?"
                size={16}
                marginBottom={16}
                options={[
                    { label: 'Yes', value: 'YES' },
                    { label: 'No', value: 'NO' }
                ]}
            />
            <Label marginBottom={4} alignSelf="flex-start">Office Phone Number</Label>
            <TextInputField name="officePhone" type="text" placeholder="Office Phone Number" />
            <RadioGroup
                name="last6MonthCases"
                label="How many clear aligner cases have you submitted in the last 6 months?"
                size={16}
                marginBottom={0}
                options={[
                    { label: '0-3', value: '0-3' },
                    { label: '4-6', value: '4-6' },
                    { label: '7-10', value: '7-10' },
                    { label: '10+', value: '10+' }
                ]}
            />
            <CandidTerms />
        </>
    )
}

export default CandidUserFields
